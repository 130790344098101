import { useQuery } from "react-query";
import { FlightTrackPointDTO } from "../../../common/dto/flight-track-point.dto";
import Http from "../../../common/utils/Http";

const fetchTracks = (sessionId: number) => {
  return Http<FlightTrackPointDTO[]>({ url: `/v2/tracker/sessions/${sessionId}/tracks` })
};

export const useTracks = (sessionId: number) => {
  return useQuery(['session', sessionId, 'tracks'], () => fetchTracks(sessionId))
}
