import React from "react";
import { Accordion, Button, Card } from 'react-bootstrap';
import { FlightTrackPointDTO } from "../../../../common/dto/flight-track-point.dto";
import { TracksChart } from "../TracksChart";
import { TracksMap } from "../TracksMap";
import { TracksTable } from "../TracksTable";
import "./index.scss";

type FlightPlanProps = {
  tracks: FlightTrackPointDTO[];
};

export const TrackSection: React.FC<FlightPlanProps> = ({ tracks }) => {
  let tracksComponents = [
    ['Tracks Map', <TracksMap tracks={tracks} />],
    ['Tracks Chart', <TracksChart tracks={tracks} />],
    ['Tracks Table', <TracksTable tracks={tracks} />],
  ];

  return <div className="tracks">
    {tracks.length > 0 ? tracksComponents.map(([name, component], index) => {
      return <Accordion defaultActiveKey="0" className="tracks_accordion">
        <Card
          className={`tracks_card ${index === 0 ? "tracks_card_first" : ""} ${index === tracksComponents.length - 1 ? "tracks_card_last" : ""}`}>
          <Card.Header className="tracks_card_header">
            <Accordion.Toggle as={Button} variant="link" eventKey={`${index}`}>
              {name}
            </Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey={`${index}`}>
            <Card.Body>
              {component}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    }) : (
      <div className="alert alert-danger" role="alert">
        <b>No tracks were found for this session.</b>
        {/* <br />
        Please note that tracks are stored for 3 months, while other data is kept for 1 year (starting from August 4th, 2024). */}
      </div>
    )}
  </div>;
};
